import Config from '../Config';
import HCNLocalData from '../helpers/hcn-local-data';

const initialState = {
    type: null,
    loading: false,
    provider: null,
    providers: [],
    availableFilters: [],
    //selectedFilters: [],
    rootCategoryCategories: [],
    searchingItems: [],
    providersPreferences: [],
    activeRootCategories: [],
    activeRootCategory: null,
    providerRootCategories: [],
    selectedRootCategoryCategoryId: null,
    simpleModalData: null,
    group: null,
    property: null,
    fontSize: 'medium',
    validCode: null,
    discount: null,
    subTotal: null,
    total: null,
    tip: null,
    pickupDescription: null,
    providerStatus: null,
    payment: false,
    cartItems: null,
    dinein: {},
    GSData: {},
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case Config.REDUX_REDUCER_TYPES.SET_LOADING:
            return { ...state, ...{ type: action.type, loading: action.payload } };
        case Config.REDUX_REDUCER_TYPES.SET_PROVIDER:
            // if (!action.payload) {
            //     HCNLocalData.remove(Config.REDUX_REDUCER_TYPES.SET_PROVIDER);
            // }

            // HCNLocalData.set(Config.REDUX_REDUCER_TYPES.SET_PROVIDER, action.payload ? action.payload.id : null);

            return {
                ...state,
                ...{ type: action.type, provider: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_PAYMENT:
            return {
                ...state,
                ...{ type: action.type, payment: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.PICKUP_DESCRIPTION:
            return {
                ...state,
                ...{ type: action.type, pickupDescription: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_PROVIDERS:
            return {
                ...state,
                ...{ type: action.type, providers: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_AVAILABLE_FILTERS:
            return {
                ...state,
                ...{ type: action.type, availableFilters: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_ROOTCATEGORY_CATEGORIES:
            return {
                ...state,
                ...{ type: action.type, rootCategoryCategories: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_SEARCHING_ITEMS:
            return {
                ...state,
                ...{ type: action.type, searchingItems: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_PROVIDERS_PREFERENCES:

            return {
                ...state,
                ...{ type: action.type, providersPreferences: action.payload ? action.payload : HCNLocalData.get(Config.REDUX_REDUCER_TYPES.SET_PROVIDERS_PREFERENCES) },
            };
        case Config.REDUX_REDUCER_TYPES.SET_ACTIVE_ROOTCATEGORIES:
            return {
                ...state,
                ...{ type: action.type, activeRootCategories: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_ACTIVE_ROOTCATEGORY:
            return {
                ...state,
                ...{ type: action.type, activeRootCategory: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_PROVIDER_ROOTCATEGORIES:
            return {
                ...state,
                ...{ type: action.type, providerRootCategories: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_SELECTED_ROOTCATEGORY_CATEGORY_ID:
            return {
                ...state,
                ...{ type: action.type, selectedRootCategoryCategoryId: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_SIMPLE_MODAL:
            return {
                ...state,
                ...{ type: action.type, simpleModalData: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_GROUP:
            return {
                ...state,
                ...{ type: action.type, group: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_PROPERTY:
            return {
                ...state,
                ...{ type: action.type, property: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.FONT_SIZE:
            return {
                ...state,
                ...{ type: action.type, fontSize: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.VALID_CODE:
            return {
                ...state,
                ...{ type: action.type, validCode: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_DISCOUNT:
            return {
                ...state,
                ...{ type: action.type, discount: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_SUBTOTAL:
            return {
                ...state,
                ...{ type: action.type, subTotal: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_TOTAL:
            return {
                ...state,
                ...{ type: action.type, total: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_TIP:
            return {
                ...state,
                ...{ type: action.type, tip: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_PROVIDER_STATUS:
            return {
                ...state,
                ...{ type: action.type, providerStatus: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_CART_ITEMS:
            return {
                ...state,
                ...{ type: action.type, cartItems: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.SET_GS_DATA:
            return {
                ...state,
                ...{ type: action.type, GSData: action.payload },
            };
        case Config.REDUX_REDUCER_TYPES.DINEIN:
            return {
                ...state,
                ...{ type: action.type, dinein: action.payload },
            };
        default:
            return state;
    }
};

export default Reducer;
