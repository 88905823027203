export const dinein = {
    en: {
        dine: '0',
        more: 'More...',
        search: 'Search...',
        specifyAllergies: 'Specify allergies',
        total: 'Total',
        haveCoupon: 'I HAVE A COUPON',
        couponCode: 'Coupon code',
        addEmailFirst: 'Add your email first',
        emailToast: 'You have to enter a valid email',
        apply: 'APPLY',
        addMoreItems: 'Add more items',
        fnameLNameToast: 'Please specify your first and last name',
        fNameLnameEmailMobile: 'Please specify your first and last name and a valid email address and a mobile number',
        close: 'Close',
        ok: 'OK',
        continue: 'Continue',
        placeOrder: 'Place order',
        clearOrder: 'Clear Order',
        personDetailes: 'Person ordering details',
        emailInfo: 'Your email address is required to receive a receipt or to use coupon codes.',
        mobileInfo: 'Your mobile number is used only for update notifications in case of a problem with your order and for delivery update notifications.',
        fName: 'First Name',
        lName: 'Last Name',
        age: 'Age',
        email: 'Email',
        mobile: 'Mobile',
        subTotal: 'Sub Total',
        discount: 'Discount',
        numToCart: 'number to cart',
        placeSettings: 'Place settings',
        specialRequest: 'Special Request: ',
        tip: 'Tip ($)',
        other: 'Other',
        enhanceMeal: 'Enhance your experience',
        selectPayment: 'Select your payment method',
        chargeDetails: 'Charge Details',
        creditCard: 'Credit card',
        payOnPhone: 'Pay on my phone',
        chargesInfo: 'If you would like a copy of your charges please fill in Person Ordering Details',
        chargeRoom: 'Your order will be delivered to the hotel lobby and will be charged to your room.',
        chargeCard: 'Your order will be delivered to the hotel lobby and will be charged to your credit card.',
        alcoholTitle: 'To purchase alcoholic products, you must be at least 21 years of age and have a valid, government-issued ID to show at the time of delivery to confirm you are of legal age.',
        disclaimersRequirements: 'Disclaimers & Requirements',
        ageRequirement: 'Age Requirement',
        alcText1: 'The purchase of alcohol by persons under the age of 21 is prohibited by law.',
        alcText2: 'You must be at least 21 years old to purchase wine or spirits.',
        alcText3: 'By placing an order through us, you are representing yourself to us as at least 21 years of age and that the person to whom accepts delivery is at least 21 years old.',
        alcText4: 'We require proof of identity before delivering an order. Acceptable IDs are State or Government ID (ie Driver’s Lic, Passport) Picture ID must include date of birth.',
        ownership: 'Ownership',
        ownText1: 'By placing an order for delivery to you, you authorize us to act on your behalf to engage a common carrier to deliver your order to you.',
        ownText2: 'Alcoholic beverages may be sold and delivered only to persons who are at least 21 years old and by placing your order with us, you represent and warrant to us that you are at least 21 years of age and that the person to whom delivery will be made is also at least 21 years of age to accept delivery.',
        ownText3: 'By ordering you agree to indemnify and hold harmless the seller for any resulting consequence or a fraudulent order or any misrepresentation as to the age or legal ability of any intended recipient to receive shipment of alcoholic beverages.',
        ownText4: 'If no one is available at the time of delivery, the package will not be left.',
        personalDetails: 'Personal Details',
        btnLegal: 'I am of legal age',
        deleteAlcohol: 'Delete Alcohol',
        terms: 'Terms & Conditions',
        privacy: 'Privacy Policy',
        weSuggest: 'We also Suggest',
        availableFrom: 'Available from ',
        rootCategoryClosed: 'MENU CLOSED - Available from ',
        rootCategoryClosedToday: 'MENU CLOSED TODAY',
        noServe: 'Menu can not serve at the moment',
        customizeOrder: 'Customize your order',
        required: 'Required',
        optional: 'Optional',
        chooseBetween: 'PLEASE CHOOSE BETWEEN',
        specialrequest: 'Special Request',
        saveEdittedItem: 'Save Edited Item',
        addToOrder: 'ADD TO ORDER',
        orderSuccess: 'Order Placed Successfully',
        deliveryTime: 'Approx. delivery time',
        persons: 'Persons',
        enjoyMeal: 'Enjoy your meal!',
        fillDetails: 'Fill in your details',
        emailValidToast: 'Please specify a valid email address',
        receiveReceipt: 'You will receive a receipt shortly. Thank you for your order.',
        sendReceipt: 'Send receipt',
        orderProcessing: 'Order processing',
        pleaseWait: 'Please wait...',
        recentSearches: 'Recent Searches',
        clearAll: 'CLEAR ALL',
        noFilters: 'There are no filters to choose from',
        cardDetails: 'Card details',
        paymentFailed: 'The payment has failed!',
        paymentSuccess: 'The payment has completed successfully!',
        DisclaimerToast: 'Disclaimer: Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may increase your risk of foodborne illness.',
        over21: 'You have to be over 21 to purchase alcoholic items.',
        emailFirst: 'You have to enter your email first',
        couponError1: 'The coupon can not be applied! Your Sub Total ',
        couponError2: ' must be greater than ',
        canNotApply: 'You can not apply the code in these cart items. Contact Help Desk for more information.',
        navigatingOut: 'You are navigating away from this restaurant. We will keep the items you have placed in your cart for a little while in case you come back soon…',
        openCartError: 'You have to add an item to the cart before you open it',
        emailMandatory: 'Email is mandatory',
        formError: 'Please make sure you have filled the receipt details correctly!',
        flowError: 'Flow error, please contact support!',
        activeCartWarning: 'You have an active cart for another restaurant. The order you are placing does <u>not</u> include the items from the other restaurant.',
        proceed: 'PROCEED',
        canNotServe: 'The restaurant can not serve at the moment',
        refreshError: 'Something is going wrong, the system will refresh the page in 2 seconds!',
        cardProcessError: 'Cannot process card, please contact your bank for more details',
        deleteItemWarning: 'Are you sure you want to delete this item ',
        payPhoneProcess: 'We have sent you an email with a payment link. When you pay click continue to complete your order.',
        payPhoneProcessError: 'You have not processed your payment yet!',
        orderNotComplete1: 'Order could not be completed at the moment. Please try again or contact support',
        orderNotComplete2: 'for more information.',
        closeRest15: 'Menu will close in less than 15 minutes! Hurry up!',
        addEmailMobile: 'You have to add your email or your mobile number!',
        groupPageDescription: 'Great local restaurants recommended by the hotel. Order online, pick up in lobby, enjoy in your room.',
        cancel: 'Cancel',

    },
};
