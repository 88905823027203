import React from 'react';
import { getTranslatedText } from '../helpers/CheckTranslations';
import { store } from '../redux/store';

const Success = () => {

    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 100,
    }}>
        <span>{getTranslatedText('paymentSuccess', store)}</span>
    </div>
}

export default Success