import axios from 'axios';
import HCNLocalData from '../helpers/hcn-local-data';
import Config from '../Config';
// import Auth from './auth';
import jwt from 'jwt-decode';
import moment from 'moment';

class Services {

    // static checkForUnauthorisedAccess(error) {
    //     if (error && error.response && error.response.status === 401) {
    //         Auth.resetAuth();
    //         window.location.replace('/#' + Config.URLs.login);
    //     }
    // }

    static checkForAccessTokenOrGenerate(forceUpdate = false) {
        return new Promise((resolve, reject) => {
            let access_token = forceUpdate ? null : HCNLocalData.get(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN) ? HCNLocalData.get(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN) : '';
            if (access_token) {
                let tokenObject = jwt(access_token);
                if (tokenObject.exp < moment().unix()) {
                    access_token = null;
                    HCNLocalData.remove(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN);
                }
            }

            if (!access_token) {
                axios.post(Config.getAuthBasePath() + '/oauth/token', {
                    'grant_type': 'client_credentials',
                }, {
                    headers: {'Content-Type': 'application/json'},
                    auth: {
                        username: Config.getAccessTokenUsername(),
                        password: Config.getAccessTokenPassword(),
                    },
                })
                    .then(function(response) {
                        if (
                            [200, 201].includes(response.status)
                        ) {
                            HCNLocalData.set(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN, response.data.access_token);
                            resolve(response.data.access_token);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            } else {
                resolve(access_token);
            }
        });
    }

    static wrapError(error) {
        if (error && error.response && error.response.status) {
            return error;
        } else {
            return {
                response: {
                    code: 500,
                    message: error && error.message ? error.message : 'An error has occurred, please try again later.',
                },
            };
        }
    }

    static getDefaultHeaders() {
        return {
            'Content-Type': 'application/json',
            'Authorization': HCNLocalData.get(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN) ? 'Bearer ' + HCNLocalData.get(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN) : '',
        };
    }

    static getApi(apiUrl) {
        return Services.checkForAccessTokenOrGenerate().then(() => {
            return new Promise((resolve, reject) => {
                axios.get(apiUrl, {
                    headers: Services.getDefaultHeaders(),
                })
                    .then(function(response) {
                        if (
                            [200, 201].includes(response.status)
                        ) {
                            resolve(response.data);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        // Services.checkForUnauthorisedAccess(error);
                        reject(Services.wrapError(error).response.data);
                    });
            });
        });
    }

    static postApi(apiUrl, criteria, useBasicAuth = false, contentType = 'application/json') {
        return Services.checkForAccessTokenOrGenerate().then(() => {
            return new Promise((resolve, reject) => {
                axios.post(apiUrl, criteria, {
                    headers: Services.getDefaultHeaders(useBasicAuth, contentType),
                })
                    .then(function(response) {
                        if (
                            [200, 201, 204].includes(response.status)
                        ) {
                            resolve(response.data);
                        } else {
                            reject(response.data);
                        }
                    })
                    .catch(function(error) {
                        reject(Services.wrapError(error).response.data);
                    },
                    );
            });
        });
    }

    // static putApi(apiUrl, criteria, callback, errorCallback) {
    //     axios.put(apiUrl, criteria,{
    //         headers: Services.getDefaultHeaders(),
    //     })
    //         .then(function(response) {
    //             if (response.data.error === false && [200, 201].includes(response.data.code)) {
    //                 callback(response.data.data);
    //             } else {
    //                 errorCallback(response.data.message);
    //             }
    //         })
    //         .catch(function(error) {
    //             Services.checkForUnauthorisedAccess(error);
    //             errorCallback(Services.wrapError(error).response.data.message);
    //         });
    // }
    //
    // static deleteApi(apiUrl, callback, errorCallback) {
    //     axios.delete(apiUrl,{
    //         headers: Services.getDefaultHeaders(),
    //     })
    //         .then(function(response) {
    //             if (response.data.error === false && [200, 201].includes(response.data.code)) {
    //                 callback(response.data.data);
    //             } else {
    //                 errorCallback(response.data.message);
    //             }
    //         })
    //         .catch(function(error) {
    //             Services.checkForUnauthorisedAccess(error);
    //             errorCallback(Services.wrapError(error).response.data.message);
    //         });
    // }
}

export default Services;
