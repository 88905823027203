import Config from '../Config';
import { dinein } from '../TranslationsDineIn'
import { marketplace } from '../TranslationsMarketplace'

export const checkTranslations = (dispatch) => {
    let isDinein = Config.isDineIn();

    if (isDinein) {
        dispatch({
            type: Config.REDUX_REDUCER_TYPES.DINEIN,
            payload: dinein,
        });
    } else {
        dispatch({
            type: Config.REDUX_REDUCER_TYPES.DINEIN,
            payload: marketplace,
        });
    }
}

export const getTranslatedText = (data, store) => {
    const rdx = store.getState().Reducer.dinein;

    return rdx.en?.[data];
}
