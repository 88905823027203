import Services from './services';
import Config from '../Config';

const UiRootCategoryItemsModel = {
    remote_getAll: async (providerId) => {
        return await Services.getApi(Config.getBasePath() + `/ui/root-category-items?providerId=${providerId}`);
    },
    remote_getAllBySearchingText: async (providerId, searchText) => {
        return await Services.getApi(Config.getBasePath() + `/ui/root-category-items?providerId=${providerId}&text=${searchText}`);
    },
    remote_getActiveRootCategories: async (providerId) => {
        return await UiRootCategoryItemsModel.remote_getAll(providerId).then(rootCategories =>
            // rootCategories = rootCategories.filter(mc => mc.open === true);

            rootCategories.sort((a, b) => a.open < b.open ? 1 : -1,
            ),
        );
    },
    // remote_getOne: rootCategoryCategoryGuid => {
    //     return UiRootCategoryItemsModel.remote_getAll().then(rootCategoryCategories => {
    //         return rootCategoryCategories.find(mc => mc.id === rootCategoryCategoryGuid);
    //     });
    // },
    // remote_deleteOne: message => {
    //     return Services.deleteApi(
    //         Services.basePath() + `/messages/${message.id}`,
    //     );
    // },
    // remote_addOne: message => {
    //     return Services.postApi(Services.basePath() + '/messages', message);
    // },
    // remote_editOne: message => {
    //     return Services.putApi(
    //         Services.basePath() + `/messages/${message.id}`,
    //         message,
    //     );
    // },
    // remote_pushMessageToDevices: message => {
    //     return Services.postApi(
    //         Services.basePath() + `/socket/property/${Config.getPropertyId()}`,
    //         message,
    //     );
    // },
};

export default UiRootCategoryItemsModel;
