import React, {useEffect} from 'react';
import './App.css';
import {HashRouter, Route, Switch} from 'react-router-dom';
import Config from './Config';
import {Provider, useDispatch} from 'react-redux';
import {store} from './redux/store';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HCNLocalData from './helpers/hcn-local-data';
import Success from './components/Success';
import Fail from './components/Fail';
import ReactGA from 'react-ga4';
import jwtDecode from 'jwt-decode';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
// replace tracking id
ReactGA.initialize('G-YCNNJ2WWLS');

function App() {
    function useQuery() {
        return new URLSearchParams(window.location.search);
    }

    let query = useQuery();
    const params = window.location.pathname;

    // useEffect(() => {
    if (params.split('/')?.length > 2) {
        let paramsArray = params.split('/');
        paramsArray?.[2] &&
            HCNLocalData.set(
                Config.LOCAL_DATA_KEYS.PROVIDER_ID,
                paramsArray[2],
            );
        paramsArray?.[4] &&
            HCNLocalData.set(Config.LOCAL_DATA_KEYS.MENU_ID, paramsArray[4]);

        let token = query.get('token');
        token && HCNLocalData.set(Config.LOCAL_DATA_KEYS.TABLET_TOKEN, token);
        setTimeout(() => {
            if (
                token &&
                HCNLocalData.get(Config.LOCAL_DATA_KEYS.PROVIDER_ID) &&
                HCNLocalData.get(Config.LOCAL_DATA_KEYS.MENU_ID)
            ) {
                window.location.href = '/#/provider-home';
            }
        }, 1000);
    } else {
        let group = query.get('group');
        let hotel = query.get('hotel');
        let room = query.get('room');
        let token = query.get('token');

        group && HCNLocalData.set(Config.LOCAL_DATA_KEYS.GROUP, group);
        token && HCNLocalData.set(Config.LOCAL_DATA_KEYS.TABLET_TOKEN, token);
        hotel && HCNLocalData.set(Config.LOCAL_DATA_KEYS.HOTEL, hotel);
        room && HCNLocalData.set(Config.LOCAL_DATA_KEYS.ROOM, room);

        if (
            !HCNLocalData.get(Config.LOCAL_DATA_KEYS.GROUP) &&
            HCNLocalData.get(Config.LOCAL_DATA_KEYS.TABLET_TOKEN)
        ) {
            setTimeout(() => {
                if (
                    !HCNLocalData.get(
                        Config.LOCAL_DATA_KEYS.GROUP &&
                            !HCNLocalData.get(
                                Config.LOCAL_DATA_KEYS.TABLET_TOKEN,
                            ),
                    )
                ) {
                    toast('Group not found');
                }
            }, 1000);
        }

        if (group || token || hotel) {
            window.location.href = '/';
        }
    }

    useEffect(() => {
        if (
            HCNLocalData.get(Config.LOCAL_DATA_KEYS.TABLET_TOKEN) ||
            HCNLocalData.get(Config.LOCAL_DATA_KEYS.GROUP)
        ) {
            window.location.href = '/#';
        }
    }, []);

    // }, [])

    // if (token && !group) {
    //     window.location.href = '/#/provider-home';
    // }

    const loading = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse">&nbsp;</div>
        </div>
    );

    const Layout = React.lazy(() => import('./components/layout/layout'));

    return (
        <div id="page" className="site">
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <ToastContainer />
                    <HashRouter>
                        <React.Suspense fallback={loading}>
                            <Switch>
                                <Route
                                    path="/"
                                    exact={true}
                                    name="Home"
                                    render={props => {
                                        let customProps = {
                                            ...props,
                                            ...{
                                                mode: Config.MAIN_MODE_TYPES
                                                    .HOME,
                                            },
                                        };
                                        return <Layout {...customProps} />;
                                    }}
                                />
                                <Route
                                    path="/provider-home/:providerId?"
                                    name="PROVIDER"
                                    render={props => {
                                        let customProps = {
                                            ...props,
                                            ...{
                                                mode: Config.MAIN_MODE_TYPES
                                                    .PROVIDER,
                                            },
                                        };
                                        return <Layout {...customProps} />;
                                    }}
                                />
                                <Route
                                    path="/rootCategory-category/:rootCategoryCategoryGuid"
                                    name="RootCategory Category"
                                    render={props => {
                                        let customProps = {
                                            ...props,
                                            ...{
                                                mode: Config.MAIN_MODE_TYPES
                                                    .ROOTCATEGORY_CATEGORY,
                                            },
                                        };
                                        return <Layout {...customProps} />;
                                    }}
                                />
                                <Route
                                    path="/search"
                                    name="Search"
                                    render={props => {
                                        let customProps = {
                                            ...props,
                                            ...{
                                                mode: Config.MAIN_MODE_TYPES
                                                    .SEARCH,
                                            },
                                        };
                                        return <Layout {...customProps} />;
                                    }}
                                />
                                <Route
                                    path="/success"
                                    // name="Search"
                                    render={props => {
                                        let customProps = {
                                            ...props,
                                            ...{
                                                mode: Config.MAIN_MODE_TYPES
                                                    .SEARCH,
                                            },
                                        };
                                        return <Success {...customProps} />;
                                    }}
                                />
                                <Route
                                    path="/fail"
                                    // name="Search"
                                    render={props => {
                                        let customProps = {
                                            ...props,
                                            ...{
                                                mode: Config.MAIN_MODE_TYPES
                                                    .SEARCH,
                                            },
                                        };
                                        return <Fail {...customProps} />;
                                    }}
                                />
                            </Switch>
                        </React.Suspense>
                    </HashRouter>
                </Provider>
            </Elements>
        </div>
    );
}

export default App;
